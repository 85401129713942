import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dbg: "test",  // "strict" unterdrückt alle, "prod" zeigt nur die wichtigen, "test" alle
    zip_path: "",   // Nach dem Upload der Pfad zum ZIP, wird in Axios gesetzt
    form_status: "incomplete",  // oder "OK" oder "sent";
    validation_nok: true,
    validation_msg: "Es braucht mindestens eine Buchblockdatei",
    jobticket: "",
    waiting_for_uploaded: 0,
    form_sent: false,
    crnt_files: [],
    cover_files: [],
    kvorsatz_files: [],
    vorsatz_files: [],
    buchblock_files: [],
    nachsatz_files: [],
    email: "",
    b_vakat_cover: false,
    b_vakat_vorsatz: false,
    b_vakat_buchblock: false,
    mm_klappe_cover_links: 0,
    mm_klappe_cover_rechts: 0,
    mm_klappe_vorsatz_links: 0,
    mm_klappe_vorsatz_rechts: 0,
    kennzahl_typ: "isbn",
    isbn: "",
    wnumber: "",
    ecnumber: "",
    jobname: "",
    b_bib: false,
    b_kanban: false,
    b_dua: false,
    b_duamat: false,
    b_kontroll: false,
    b_fremdrechte: false,
    b_kooperation: false,
    b_webshop: false,
    b_pixeln: false,
    n_vector_count: 500,
    ppi_resolution: 200,
    jpg_quality:"jq_high",
    maintenance_state: false
  },
  getters: {
    // wird im component mit $store.getters.getHuba aufgerufen
    // kan man auch über ein computed-property kapseln, damit der template-markup nicht kompliziert wird
    get_zip_path: state => {
      return state.zip_path;
    },
    get_form_status: state => {
      return state.form_status;
    },
    get_validation_nok: state => {
      return state.validation_nok;
    },
    get_validation_msg: state => {
      return state.validation_msg;
    },
    get_jobticket: state => {
      return state.jobticket;
    },
    get_form_sent: state => {
      return state.form_sent;
    },
    get_waiting_for_uploaded: state => {
      return state.waiting_for_uploaded;
    },
    
    get_crnt_files: state => {
      return state.crnt_files;
    },
    get_cover_files: state => {
      return state.cover_files;
    },
    get_kvorsatz_files: state => {
      return state.kvorsatz_files;
    },
    get_vorsatz_files: state => {
      return state.vorsatz_files;
    },
    get_buchblock_files: state => {
      return state.buchblock_files;
    },
    get_nachsatz_files: state => {
      return state.nachsatz_files;
    },
    
    get_email: state => {
      return state.email;
    },
    
    get_b_vakat_cover: state => {
      return state.b_vakat_cover;
    },
    get_b_vakat_buchblock: state => {
      return state.b_vakat_buchblock;
    },
    get_b_vakat_vorsatz: state => {
      return state.b_vakat_vorsatz;
    },
    
    get_mm_klappe_cover_links: state => {
      return state.mm_klappe_cover_links;
    },
    get_mm_klappe_cover_rechts: state => {
      return state.mm_klappe_cover_rechts;
    },
    get_mm_klappe_vorsatz_links: state => {
      return state.mm_klappe_vorsatz_links;
    },
    get_mm_klappe_vorsatz_rechts: state => {
      return state.mm_klappe_vorsatz_rechts;
    },
    
    get_kennzahl_typ: state => {
      return state.kennzahl_typ;
    },
    get_isbn: state => {
      return state.isbn;
    },
    get_wnumber: state => {
      return state.wnumber;
    },
    get_ecnumber: state => {
      return state.ecnumber;
    },
    
    get_jobname: state => {
      return state.jobname;
    },
    get_b_bib: state => {
      return state.b_bib;
    },
    get_b_kanban: state => {
      return state.b_kanban;
    },
    get_b_dua: state => {
      return state.b_dua;
    },
    get_b_duamat: state => {
      return state.b_duamat;
    },
    get_b_kontroll: state => {
      return state.b_kontroll;
    },
    get_b_kooperation: state => {
      return state.b_kooperation;
    },
    get_b_webshop: state => {
      return state.b_webshop;
    },
    get_b_fremdrechte: state => {
      return state.b_fremdrechte;
    },
    get_b_pixeln: state => {
      return state.b_pixeln;
    },
    get_n_vector_count: state => {
      return state.n_vector_count;
    },
    get_ppi_resolution: state => {
      return state.ppi_resolution;
    },
    get_jpg_quality: state => {
      return state.jpg_quality;
    },
    get_maintenance_state: state => {
      return state.maintenance_state;
    },
  },
  mutations: {
    add_crnt_files: ( state, files ) => {
      if (state.dbg === "test") console.log( "Dateien im State ablegen", files);
      // ---------------------------------------------------------------------------------------------------------
      //  Kann man aus dem Dateinamen die ISBN extrahieren?
      // ---------------------------------------------------------------------------------------------------------
      let fill_isbn = (name) => {
        if (!state.isbn || state.isbn === "isbn") {
          if ( state.dbg !== "strict" ) console.log( "ISBN aus '" + name + " berechnen");
          // ---------------------------------------------------------------------------------------------------------
          //  Aus der Flex-App: /^(978-3-12-\d{6}-.)|(978-3-\d{3}-\d{5}-.)|(978-3-\d{4}-\d{4}-.)|(978-3-\d{5}-\d{3}-.)$/
          // ---------------------------------------------------------------------------------------------------------
          var name_bit = name.match(/3-12-\d{6}/);
          if (!name_bit) name_bit = name.match(/3-\d{3}-\d{5}/);
          if (!name_bit) name_bit = name.match(/3-\d{4}-\d{4}/);
          if (!name_bit) name_bit = name.match(/3-\d{5}-\d{3}/);
          if (!name_bit) {
            name_bit = name.match(/\d{6}/);
            if (name_bit) name_bit[0] = "3-12-" + name_bit[0];
          }
          // ---------------------------------------------------------------------------------------------------------
          //  name_bit ist jetzt null oder [ treffer, anderer_kram ]
          // ---------------------------------------------------------------------------------------------------------
          if (name_bit) {
            // ---------------------------------------------------------------------------------------------------------
            //  Die 13. Ziffer ist Checksumme, die ich berechnen soll
            //  https://de.wikipedia.org/wiki/European_Article_Number#Pr%C3%BCfziffernberechnung_in_Java
            // ---------------------------------------------------------------------------------------------------------
            let chars = ("978" + name_bit[0].replace(/-/g, "")).split("");
            let digits = chars.map(function (x) {
              return Number(x);
            });
            let sum = 0;
            let mult = 3;
            for (let n = digits.length - 1; n >= 0; n--) {
              sum += digits[n] * mult;
              mult = (mult === 3) ? 1 : 3;
            }
            let sum9 = sum + 9;
            let tenner = sum9 - (sum9 % 10);
            state.isbn = "978-" + name_bit + "-" + (tenner - sum).toString();
            state.jobname = state.isbn;
            if ( state.dbg !== "strict" ) console.log( "ISBN gefunden: ", state.isbn );
          }
        }
      };
  
      for (let n = 0; n < files.length; n++ ) {
        if (state.dbg === "test") console.log( "Datei Nr " + n, files[n].name);
        try {
          fill_isbn( files[n].name );
        } catch(e) {
          console.error( "Fehler beim isbn auslesen", e);
        }
        
        var listname = estimate_list(files[n].name);
        listname += "_files";
        
        if ( state[listname].length ) {
          // console.log( "schon was in Liste");
          let insert_here = 0;
          while (insert_here < state[listname].length && files[n].name > state[listname][insert_here].name) {
            insert_here++
          }
          // console.log( "Einfügen nach " + insert_here);
          state[listname].splice(insert_here, 0, {
            ix: state[listname].length,
            file: files[n],
            name: files[n].name,
          });
        } else {
          // console.log( "nix in Liste");
          state[listname].push({
            ix: state[listname].length,
            file: files[n],
            name: files[n].name,
          });
        }
      }
      
      function estimate_list( name ) {
        if (state.dbg !== "strict") console.log( "In welche Liste gehört " + name + "?");
        if (
            name.search(/_umschlag/i) !== -1 ||
            name.search(/_cover/i) !== -1 ||
            name.search(/_ordner/i) !== -1 ||
            name.search(/_U1/) !== -1 ||
            name.search(/_ueberzug/i) !== -1 ||
            name.search(/_US/) !== -1
        ) {
          if (state.dbg !== "strict") console.log("Umschlag");
          return "cover";
        }
        if (
            name.search(/_hinterervorsatz/i) !== -1 ||
            name.search(/_hinterer_vorsatz/i) !== -1 ||
            name.search(/_hinvorsatz/i) !== -1 ||
            name.search(/_hint_vs/i) !== -1 ||
            name.search(/_vorsatz_h/i) !== -1 ||
            name.search(/_vorsatz_hint/i) !== -1 ||
            name.search(/_HV/) !== -1
        ) {
          if (state.dbg !== "strict") console.log("Hinterer Vorsatz");
          return "nachsatz";
        }
        // if (
        //     name.search(/klappe/i) !== -1
        // ) {
        //   return "kvorsatz";
        // }
        if (
            name.search(/_vorderervorsatz/i) !== -1 ||
            name.search(/_vorderer_vorsatz/i) !== -1 ||
            name.search(/_vorvorsatz/i) !== -1 ||
            name.search(/_vorsatz/i) !== -1 ||
            name.search(/_vorsatz_v/i) !== -1 ||
            name.search(/_VV/) !== -1 ||
            name.search(/_vordvors/i) !== -1 ||
            name.search(/_vorsatz_vorn/i) !== -1
        ) {
          if (state.dbg !== "strict") console.log("Vorsatz");
          return "vorsatz";
        }
        if (state.dbg !== "strict") console.log("Kein Fragment gefunden -> Buchblock");
        return "buchblock";
      }
    },
    set_files: (state, params /*files, which_list*/ ) => {
      let files = params.files;
      let which_list = params.list_name;
      // console.log("setting " + which_list, files);
      state[ which_list + "_files"] = files;
    },
  
    remove_file_from_lists: (state, filename) => {
      let listnames = ['cover_files', 'kvorsatz_files', 'vorsatz_files', 'buchblock_files', 'nachsatz_files'];
      outer_loop: for ( let n_listnames = 0; n_listnames < listnames.length; n_listnames++ ) {
        let lname = listnames[n_listnames];
        for ( let n_files = state[ lname ].length-1; n_files >= 0; n_files-- ) {
          if ( state[ lname ][n_files].name === filename ) {
            state[ lname ].splice( n_files, 1 );
            break outer_loop;
          }
        }
      }
    },
    
    set_zip_path: (state, data ) => {
      state.zip_path = data;
    },
    set_form_status: (state, data ) => {
      state.form_status = data;
    },
    set_validation_nok: (state, data ) => {
      state.validation_nok = data;
    },
    set_validation_msg: (state, data ) => {
      state.validation_msg = data;
    },
    set_jobticket: (state, data ) => {
      state.jobticket = data;
    },
    set_form_sent: (state, data ) => {
      state.form_sent = data;
    },
    set_waiting_for_uploaded: (state, data ) => {
      state.waiting_for_uploaded = data;
    },
    incr_waiting_for_uploaded: (state) => {
      state.waiting_for_uploaded++;
    },
    decr_waiting_for_uploaded: (state) => {
      state.waiting_for_uploaded--;
      if ( state.waiting_for_uploaded < 0 ) state.waiting_for_uploaded = 0;
    },
    
    set_email: (state, data ) => {
      state.email = data;
      localStorage.setItem('kps4_email', data);
    },
    
    set_b_vakat_cover: (state, data ) => {
      state.b_vakat_cover = data;
    },
    set_b_vakat_vorsatz: (state, data ) => {
      state.b_vakat_vorsatz = data;
    },
    set_b_vakat_buchblock: (state, data ) => {
      state.b_vakat_buchblock = data;
    },
    
    set_mm_klappe_cover_links: (state, data ) => {
      state.mm_klappe_cover_links = data;
    },
    set_mm_klappe_vorsatz_links: (state, data ) => {
      state.mm_klappe_vorsatz_links = data;
    },
    set_mm_klappe_cover_rechts: (state, data ) => {
      console.log( "store", data);
      state.mm_klappe_cover_rechts = data;
    },
    set_mm_klappe_vorsatz_rechts: (state, data ) => {
      state.mm_klappe_vorsatz_rechts = data;
    },
    
    set_kennzahl_typ: (state, data ) => {
      state.kennzahl_typ = data;
      state.jobname = state[ data ];
    },
    set_isbn: (state, data ) => {
      state.isbn = data;
      state.jobname = data;
    },
    set_wnumber: (state, data ) => {
      state.wnumber = data;
      state.jobname = data;
    },
    set_ecnumber: (state, data ) => {
      state.ecnumber = data;
      state.jobname = data;
    },
    
    set_b_bib: (state, data ) => {
      state.b_bib = data;
    },
    set_b_dua: (state, data ) => {
      state.b_dua = data;
    },
    set_b_kanban: (state, data ) => {
      state.b_kanban = data;
    },
    set_b_duamat: (state, data ) => {
      state.b_duamat = data;
    },
    set_b_kontroll: (state, data ) => {
      state.b_kontroll = data;
    },
    set_b_kooperation: (state, data ) => {
      state.b_kooperation = data;
    },
    set_b_webshop: (state, data ) => {
      state.b_webshop = data;
    },
    set_b_fremdrechte: (state, data ) => {
      state.b_fremdrechte = data;
    },
    
    set_b_pixeln: (state, data ) => {
      state.b_pixeln = data;
    },
    set_n_vector_count: (state, data ) => {
      state.n_vector_count = data;
    },
    set_ppi_resolution: (state, data ) => {
      state.ppi_resolution = data;
    },
    set_jpg_quality: (state, data ) => {
      state.jpg_quality = data;
    },
    set_maintenance_state: (state, data ) => {
      state.maintenance_state = data;
    },
  },
  actions: {
    validate ( context ) {
      
      if (context.dbg !== "strict") console.log("Validatieren...");
      let msgs = [];
      let pass = true;
  
      if (
          context.getters.get_cover_files.length === 0 &&
          context.getters.get_vorsatz_files.length === 0 &&
          context.getters.get_kvorsatz_files.length === 0 &&
          context.getters.get_buchblock_files.length === 0 &&
          context.getters.get_nachsatz_files.length === 0
      ) {
        if (context.dbg !== "strict") console.log("ISBN-reset");
        context.commit('set_isbn', "")
      }
      
      if ( context.getters.get_buchblock_files.length === 0 ) {
        if (context.dbg !== "strict") console.log("Kein Buchblock");
        msgs.push( "Wählen Sie mindestens eine Datei für den Buchblock." );
      }
      
      if ( context.getters.get_cover_files.length > 2 ) {
        if (context.dbg !== "strict") console.log("Zu viel Umschlag");
        msgs.push( "Wählen Sie für den Umschlag entweder EINE Datei mit allen U aus oder ZWEI mit U4U1 sowie U2U3." );
      }
      
      if ( context.getters.get_b_dua && context.getters.get_cover_files.length < 1 ) {
        if (context.dbg !== "strict") console.log("Kein Umschlag");
        msgs.push( "Das Format DUA muss einen Umschlag haben.");
      }
      
      if ( context.getters.get_b_duamat && context.getters.get_cover_files.length < 1 ) {
        if (context.dbg !== "strict") console.log("Kein Umschlag");
        msgs.push( "Das Format DUAMat muss einen Umschlag haben.");
      }
      
      if ( context.getters.get_b_kanban && context.getters.get_cover_files.length < 1 ) {
        if (context.dbg !== "strict") console.log("Kein Umschlag");
        msgs.push( "Das Format Kanban muss einen Umschlag haben.");
      }
      
      if ( context.getters.get_email.search(/^[^@]+@[^@]+\.[^@]+$/) === -1 ) {
        if (context.dbg !== "strict") console.log("Keine E-Mail");
        msgs.push( "Bitte geben Sie eine korrekte E-Mail Adresse ein.");
      }
      
      
      if (
          context.getters.get_kennzahl_typ === "isbn" &&
          context.getters.get_isbn.search(/(978-3-12-\d{6}-.)|(978-3-\d{3}-\d{5}-.)|(978-3-\d{4}-\d{4}-.)|(978-3-\d{5}-\d{3}-.)/) === -1 )
      {
        if (context.dbg !== "strict") console.log("Keine ISBN");
        msgs.push("Bitte geben Sie eine korrekte ISBN ein.");
      }
      if (
          context.getters.get_kennzahl_typ === "wnumber" &&
          context.getters.get_wnumber.length < 3
      ){
        if (context.dbg !== "strict") console.log("Keine W-Nummer");
          msgs.push("Bitte geben Sie eine W-Nummer ein.");
      }
      if (
          context.getters.get_kennzahl_typ === "ecnumber" &&
          context.getters.get_ecnumber.length < 3 )
      {
        if (context.dbg !== "strict") console.log("EC-Nummer");
          msgs.push("Bitte geben Sie eine EC-Nummer ein.");
      }
      
      if (
          ! context.getters.get_b_bib &&
          ! context.getters.get_b_kanban &&
          ! context.getters.get_b_kontroll &&
          ! context.getters.get_b_webshop &&
          ! context.getters.get_b_kooperation &&
          ! context.getters.get_b_fremdrechte &&
          ! context.getters.get_b_duamat &&
          ! context.getters.get_b_dua
      ) {
        if (context.dbg !== "strict") console.log("Kein Ausgabeformat");
        msgs.push( "Bitte kreuzen Sie wenigstens ein Ausgabeformat an.");
      }
      
      if ( isNaN( Number( context.getters.get_n_vector_count )) || isNaN( Number( context.getters.get_ppi_resolution )) ) {
        if (context.dbg !== "strict") console.log("Keine Zahl bei Auflösung");
        msgs.push( "Bitte geben Sie für das Verpixeln nur Zahlen ein.");
      }
      if ( /*window.location.host.search(/localhost/) === -1 &&*/ context.getters.get_waiting_for_uploaded > 0 ) {
        if (context.dbg !== "strict") console.log("Uploads noch nicht fertig");
        msgs.push( "Es müssen noch " + context.getters.get_waiting_for_uploaded + " Dateien hochgeladen werden.");
      }
      if ( msgs.length ) {
        // ⟥⧇⨀⨅⩒⩨⟐◊
        context.commit( 'set_validation_msg', msgs.join("&nbsp;&nbsp;&#x2B27;&nbsp;&nbsp;") );
        context.commit('set_validation_nok', true);
        context.commit('set_form_status', 'incomplete')
      } else {
        context.commit( 'set_validation_msg', "" );
        context.commit('set_validation_nok', false);
        context.commit('set_form_status', 'OK');
        context.dispatch('assemble_jt');
      }
    },
    assemble_jt (context) {
      if (context.dbg !== "strict") console.log("Jobticket erstellen");
      let paket_name = context.getters.get_isbn;
      if ( context.getters.get_kennzahl_typ === "wnumber" ) paket_name = context.getters.get_wnumber;
      if ( context.getters.get_kennzahl_typ === "ecnumber" ) paket_name = context.getters.get_ecnumber;
      if ( ! paket_name ) paket_name = "temp";
      
      let ulist = context.getters.get_cover_files;
      let vvklist = context.getters.get_kvorsatz_files;
      let vvlist = context.getters.get_vorsatz_files;
      let blist = context.getters.get_buchblock_files;
      let hvlist = context.getters.get_nachsatz_files;
      if (context.dbg) console.log("zwei");
      
      let toc = "<?xml version='1.0' ?>\n";
      toc += "<paket id=\""+paket_name+"\" version=\"3.0\">\n";
      toc += "\t<pdfs>\r";
      toc += "\t\t<umschlag>\r";
      for (let n = 0; n < ulist.length; n++) {
        toc += "\t\t\t<pdf>"+ulist[n].name+"</pdf>\n";
      }
      toc += "\t\t</umschlag>\n";
      toc += "\t\t<kvorsatz>\n";
      for (let n = 0; n < vvklist.length; n++) {
        toc += "\t\t\t<pdf>"+vvklist[n].name+"</pdf>\n";
      }
      toc += "\t\t</kvorsatz>\n";
      toc += "\t\t<vorsatz>\n";
      for (let n = 0; n < vvlist.length; n++) {
        toc += "\t\t\t<pdf>"+vvlist[n].name+"</pdf>\n";
      }
      toc += "\t\t</vorsatz>\n";
      toc += "\t\t<buch>\n";
      for (let n = 0; n < blist.length; n++) {
        toc += "\t\t\t<pdf>"+blist[n].name+"</pdf>\n";
      }
      toc += "\t\t</buch>\n";
      toc += "\t\t<nachsatz>\n";
      for (let n = 0; n < hvlist.length; n++) {
        toc += "\t\t\t<pdf>"+hvlist[n].name+"</pdf>\n";
      }
      toc += "\t\t</nachsatz>\n";
      toc += "\t</pdfs>\n";
  
      toc += "\t<params>\n";
      toc += "\t\t<email>"+context.getters.get_email+"</email>\n";
      toc += "\t\t<ecnr>"+context.getters.get_ecnumber+"</ecnr>\n";
      toc += "\t\t<sonst>"+context.getters.get_wnumber+"</sonst>\n";
      toc += "\t\t<u_vakat>"+context.getters.get_b_vakat_cover+"</u_vakat>\n";
      toc += "\t\t<v_vakat>"+context.getters.get_b_vakat_vorsatz+"</v_vakat>\n";
      toc += "\t\t<b_vakat>"+context.getters.get_b_vakat_buchblock+"</b_vakat>\n";
      toc += "\t\t<dua>"+context.getters.get_b_dua+"</dua>\n";
      toc += "\t\t<duamat>"+context.getters.get_b_duamat+"</duamat>\n";
      toc += "\t\t<kontroll>"+context.getters.get_b_kontroll+"</kontroll>\n";
      toc += "\t\t<kooperation>"+context.getters.get_b_kooperation+"</kooperation>\n";
      toc += "\t\t<webshop>"+context.getters.get_b_webshop+"</webshop>\n";
      toc += "\t\t<fremdrechte>"+context.getters.get_b_fremdrechte+"</fremdrechte>\n";
//      toc += "\t\t<dsb>"+dsb.selected.toString()+"</dsb>\n";
      toc += "\t\t<bib>"+context.getters.get_b_bib+"</bib>\n";
      toc += "\t\t<kanban>"+context.getters.get_b_kanban+"</kanban>\n";
//      toc += "\t\t<korrektur>"+korrektur.selected.toString()+"</korrektur>\n";
      toc += "\t\t<px_yesno>" + context.getters.get_b_pixeln + "</px_yesno>\n";
      toc += "\t\t<px_vec_cnt>" + context.getters.get_n_vector_count + "</px_vec_cnt>\n";
      toc += "\t\t<px_res>" + context.getters.get_ppi_resolution + "</px_res>\n";
      toc += "\t\t<px_jq>" + context.getters.get_jpg_quality + "</px_jq>\n";
      toc += "\t\t<uklappe_links>" + context.getters.get_mm_klappe_cover_links + "</uklappe_links>\n";
      toc += "\t\t<uklappe_rechts>" + context.getters.get_mm_klappe_cover_rechts + "</uklappe_rechts>\n";
      toc += "\t\t<vklappe_links>" + context.getters.get_mm_klappe_vorsatz_links + "</vklappe_links>\n";
      toc += "\t\t<vklappe_rechts>" + context.getters.get_mm_klappe_vorsatz_rechts + "</vklappe_rechts>\n";
      
      toc += "\t</params>\n";
      toc += "</paket>\n";
      
      context.commit('set_jobticket', toc);
    }
  },
  modules: {
  },
  
})
