<template>
  <div :class="maincss" class="relative" >

    <h2 class=" font-bold">Sortieren Sie die PDFs in die Bereiche per Drag & Drop</h2>

<!--    <DraggableList :list="crnt_files" name="crnt" label="Unsortierte Dateien"></DraggableList>-->

    <DraggableList :list="cover_files" name="cover" label="Umschlag"></DraggableList>
    <div v-if="$store.getters.get_cover_files.length" class="flex justify-start pl-3 mt-1 space-x-4 text-sm bg-white">
      <div class="pt-2">Klappenbreite in mm:</div>
      <input v-model.number="mm_klappe_cover_links" type="number" min="0" class="w-12 border border-gray-300 rounded text-center" value="0" />
<!--      <img src="@/assets/pages_icon.svg" class="h-6">-->
      <img :src="cover_icon">
      <input v-model.number="mm_klappe_cover_rechts" type="number" min="0" class="w-12 border border-gray-300 rounded text-center" value="0">
    </div>

    <label v-if="$store.getters.get_cover_files.length" class="block mt-3 mb-1 text-sm px-3 pt-4 pb-3 "><input type="checkbox" v-model="b_vakat_cover" class="mr-2">Vakatseite nach  Umschlag </label>

<!--    <DraggableList :list="kvorsatz_files" name="kvorsatz" label="Vorderer Vorsatz mit Klappe"></DraggableList>-->
<!--    <div v-if="$store.getters.get_kvorsatz_files.length" class="flex justify-start pl-3 mt-1 space-x-4 text-sm bg-white">-->
<!--      <div>Klappenbreite in mm:</div>-->
<!--      <input v-model.number="mm_klappe_vorsatz_links" type="number" class="w-12 border border-gray-300 rounded text-center" value="0">-->
<!--      <img src="@/assets/pages_icon.svg" class="h-6">-->
<!--      <input v-model.number="mm_klappe_vorsatz_rechts" type="number" class="w-12 border border-gray-300 rounded text-center" value="0">-->
<!--    </div>-->

    <DraggableList :list="vorsatz_files" name="vorsatz" label="Vorderer Vorsatz"></DraggableList>

    <label v-if="$store.getters.get_vorsatz_files.length" class="block mt-3 mb-1 text-sm px-3 pt-4 pb-3 "><input type="checkbox" v-model="b_vakat_vorsatz" class="mr-2">Vakatseite nach Vorsatz </label>

    <DraggableList :list="buchblock_files" name="buchblock" label="Buchblock"></DraggableList>

    <!-- border-t border-b border-gray-200 -->
    <label v-if="$store.getters.get_buchblock_files.length" class="block mt-3 mb-1 text-sm px-3 pt-4 pb-3 "><input type="checkbox" v-model="b_vakat_buchblock" class="mr-2">Vakatseite nach Buchblock </label>

    <DraggableList :list="nachsatz_files" name="nachsatz" label="Hinterer Vorsatz"></DraggableList>

    <div v-if="form_sent" v-on:click="do_nothing" class="absolute top-0 left-0 bottom-0 right-0 z-50 "  style="background: rgba(255,255,255,0.2); backdrop-filter: blur(2px);"></div>

  </div>
</template>
<script>
  import draggable from 'vuedraggable';
  import DraggableList from "../components/DraggableList";
  export default {
    name: 'Sorter',
    data () {
      return {
        defaultcss: [ "px-6",  "overflow-auto", "text-left", "border-r"]
      }
    },
    props: {
      cssclasses: Array
    },
    components: {
      DraggableList,
      draggable,
    },
    computed: {
      maincss () {
        return [ ...this.defaultcss, ...this.cssclasses ];
      },
      cover_icon() {
        let name = "";
        if ( this.mm_klappe_cover_links === 0 && this.mm_klappe_cover_rechts === 0 ) {
          name = (this.cover_files.length === 1) ? "u4u1" : "u4u1u2u3";
        }
        if ( this.mm_klappe_cover_links !== 0 && this.mm_klappe_cover_rechts === 0 ) {
          name = (this.cover_files.length === 1) ? "k4u4u1" : "k4u4u1u2u3k3";
        }
        if ( this.mm_klappe_cover_links === 0 && this.mm_klappe_cover_rechts !== 0 ) {
          name = (this.cover_files.length === 1) ? "u4u1k1" : "u4u1k1k2u2u3";
        }
        if ( this.mm_klappe_cover_links !== 0 && this.mm_klappe_cover_rechts !== 0 ) {
          name = (this.cover_files.length === 1) ? "k4u4u1k1" : "k4u4u1k1k2u2u3k3";
        }
        return "klappen/" + name + ".png";
      },
      // crnt_files: {
      //   get() { return this.$store.getters.get_crnt_files },
      //   set(value) { this.$store.commit('set_crnt_files', value) }
      // },
      form_sent: {
        get() { return this.$store.getters.get_form_sent}
      },
      cover_files: {
        get() { return this.$store.getters.get_cover_files },
        set(value) {
          this.$store.commit('set_files', value, "cover")
        }
      },
      kvorsatz_files: {
        get() { return this.$store.getters.get_kvorsatz_files },
        set(value) { this.$store.commit('set_files', value, "kvorsatz") }
      },
      vorsatz_files: {
        get() { return this.$store.getters.get_vorsatz_files },
        set(value) { this.$store.commit('set_files', value, "vorsatz") }
      },
      buchblock_files: {
        get() { return this.$store.getters.get_buchblock_files },
        set(value) { this.$store.commit('set_files', value, "buchblock") }
      },
      nachsatz_files: {
        get() { return this.$store.getters.get_nachsatz_files },
        set(value) { this.$store.commit('set_files', value, "nachsatz") }
      },

      b_vakat_buchblock: {
        get() { return this.$store.getters.get_b_vakat_buchblock },
        set(value) { this.$store.commit('set_b_vakat_buchblock', value) }
      },
      b_vakat_cover: {
        get() { return this.$store.getters.get_b_vakat_cover },
        set(value) { this.$store.commit('set_b_vakat_cover', value) }
      },
      b_vakat_vorsatz: {
        get() { return this.$store.getters.get_b_vakat_vorsatz },
        set(value) { this.$store.commit('set_b_vakat_vorsatz', value) }
      },

      mm_klappe_cover_links: {
        get() { return this.$store.getters.get_mm_klappe_cover_links },
        set(value) { this.$store.commit('set_mm_klappe_cover_links', value) }
      },
      mm_klappe_cover_rechts: {
        get() { return this.$store.getters.get_mm_klappe_cover_rechts },
        set(value) { this.$store.commit('set_mm_klappe_cover_rechts', value) }
      },
      mm_klappe_vorsatz_links: {
        get() { return this.$store.getters.get_mm_klappe_vorsatz_links },
        set(value) { this.$store.commit('set_mm_klappe_vorsatz_links', value) }
      },
      mm_klappe_vorsatz_rechts: {
        get() { return this.$store.getters.get_mm_klappe_vorsatz_rechts },
        set(value) { this.$store.commit('set_mm_klappe_vorsatz_rechts', value) }
      },


      // vorsatz_files: {
      //   get() { return this.$store.getters.get_vorsatz_files },
      //   set(value) { this.$store.commit('set_vorsatz_files', value) }
      // },
      // buchblock_files: {
      //   get() { return this.$store.getters.get_buchblock_files },
      //   set(value) { this.$store.commit('set_buchblock_files', value) }
      // },
      // nachsatz_files: {
      //   get() { return this.$store.getters.get_nachsatz_files },
      //   set(value) { this.$store.commit('set_nachsatz_files', value) }
      // }
    },
    methods: {
      do_nothing () {
      },

    }
  }
</script>
