<template>
  <div :class="maincss" >

    <div class="relative">
      <h2 class=" font-bold mb-6">Geben Sie die Auftragsdaten ein</h2>
      <label class="flex block flex-wrap mt-2 text-sm"><div class="w-full lg:w-1/3">E-Mail:</div><input  v-model="email" class="border border-gray-400  rounded w-full lg:w-2/3 p-1 " placeholder="ma@klett.de"></label>
      <div class="h-8"> </div>
      <label class="flex block mt-2 text-sm">
        <div class="hidden lg:inline lg:w-1/3">&nbsp;</div>
        <div class="space-x-2 whitespace-nowrap">
          <input type="radio" name="kennzahl" v-model="kennzahl_typ" value="isbn"> ISBN &nbsp;&nbsp;
          <input type="radio" name="kennzahl" v-model="kennzahl_typ" value="wnumber"> W-Nummer &nbsp;&nbsp;
          <input type="radio" name="kennzahl" v-model="kennzahl_typ" value="ecnumber"> EC-Nummer
        </div>
      </label>

      <label class="flex block mt-2 text-sm whitespace-nowrap" v-if="kennzahl_typ === 'isbn'" >
        <span class="hidden lg:inline lg:w-1/3">ISBN:</span>
        <input name="isbn" v-model="isbn" class="border border-gray-400  rounded w-full lg:w-2/3 p-1 " placeholder="978-3-12-123456-2">
      </label>
      <label class="flex block mt-2 text-sm whitespace-nowrap " v-if="kennzahl_typ === 'wnumber'" >
        <span class="hidden lg:inline lg:w-1/3">W-Nummer:</span>
        <input name="wnumber" v-model="wnumber" class="border border-gray-400  rounded w-full lg:w-2/3 p-1 " placeholder="W123456">
      </label>
      <label class="flex block mt-2 text-sm whitespace-nowrap" v-if="kennzahl_typ === 'ecnumber'">
        <span class="hidden lg:inline lg:w-1/3 whitespace-nowrap">EC Nummer:</span>
        <input name="ecnumber" v-model="ecnumber" class="border border-gray-400  rounded w-full lg:w-2/3 p-1 " placeholder="ECA12345EBA12">
      </label>

      <div class="h-8"> </div>

      <h2 class=" font-bold mb-6">Wählen Sie die zu erstellenden Derivate aus</h2>

<!--      <div class="flex justify-start flex-wrap space-x-2">-->
      <div>
        <label class="block mt-2 text-sm" title="Erzeugt die BIB-Variante"><input type="checkbox" v-model="b_bib" class="mr-2">BiB </label>
        <label class="block mt-2 text-sm" title="Erzeugt die Kanban-Variante"><input type="checkbox" v-model="b_kanban" class="mr-2">Kanban </label>
        <label class="block mt-2 text-sm" title="Erzeugt die DUAMat-Variante"><input type="checkbox" v-model="b_duamat" class="mr-2">DUAMat </label>
        <label class="block mt-2 text-sm" title="Erzeugt die DUA-Variante"><input type="checkbox" v-model="b_dua" class="mr-2">DUA </label>
        <label class="block mt-2 text-sm" title="Erzeugt ein Kontroll-PDF"><input type="checkbox" v-model="b_kontroll" class="mr-2">Kontroll-PDF </label>
        <label class="block mt-2 text-sm" title="Erzeugt ein Fremdrechte-PDF"><input type="checkbox" v-model="b_fremdrechte" class="mr-2">Fremdrechte-PDF </label>
        <label class="block mt-2 text-sm" title="Erzeugt ein Kooperations-PDF"><input type="checkbox" v-model="b_kooperation" class="mr-2">Kooperations-PDF </label>
        <label class="block mt-2 text-sm" title="Erzeugt ein Webshop-PDF"><input type="checkbox" v-model="b_webshop" class="mr-2">Webshop-PDF </label>
      </div>
      <div class="h-8"> </div>

      <h2 class=" font-bold mb-6">Zusätzliche Einstellungen</h2>

      <label class="block mt-2 text-sm"><input type="checkbox" v-model="b_pixeln" class="mr-2">Komplexe Seiten verpixeln </label>
      <transition name="fade">
        <div v-if="$store.getters.get_b_pixeln">
        <label class="flex flex-wrap block mt-2 text-sm">
          <div class="w-full lg:w-1/3">Ab n Vektoren:</div>
          <input  v-model="n_vector_count" class="border border-gray-400  rounded w-full lg:w-2/3 p-1 ">
        </label>
        <label class="flex flex-wrap block mt-2 text-sm">
          <div class="w-full lg:w-1/3">Auflösung:</div>
          <input  v-model="ppi_resolution" class="border border-gray-400  rounded w-full lg:w-2/3 p-1 ">
        </label>
        <label class="flex flex-wrap block mt-2 text-sm">
          <div class="w-full lg:w-1/3">Qualität:</div>
          <select v-model="jpg_quality" class="border border-gray-400  rounded w-full lg:w-2/3 p-1 ">
            <option value="jq_none">verlustfrei</option>
            <option value="jq_max">JPG Maximal</option>
            <option value="jq_high">JPG Hoch</option>
            <option value="jq_mid">JPG Mittel</option>
            <option value="jq_low">JPG Niedrig</option>
            <option value="jq_min">JPG Minimal</option>
          </select>
        </label>
        </div>
      </transition>

      <div v-if="form_sent"  v-on:click="do_nothing" class="absolute top-0 left-0 bottom-0 right-0 z-50 " style="background: rgba(255,255,255,0.2); backdrop-filter: blur(2px);"></div>

    </div>
    <!-- --------------------------------------------------------------------------  Buttons von Tailwind UI ------------------------------- -->

    <button v-on:click="reload_page" class="w-full flex items-center mt-8 px-3 py-2 space-x-4 border border-transparent shadow text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-red-200 focus:bg-white focus:outline-none ">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" width="24" height="24" viewBox="0 0 24.00 24.00" enable-background="new 0 0 24.00 24.00" xml:space="preserve">
	<path fill="#000000" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 10.9946,3.9993C 13.0452,3.9993 15.0888,4.77005 16.6515,6.33275C 19.7769,9.45816 19.7697,14.514 16.6444,17.6394C 14.8067,19.4771 12.3015,20.2367 9.91388,19.9153L 10.4373,17.9619C 12.1535,18.1207 13.9253,17.5442 15.2372,16.2322C 17.5778,13.8917 17.5778,10.0875 15.2372,7.74697C 14.0634,6.57317 12.529,6.01455 10.9946,6.02163L 10.9946,10.5754L 6.04487,5.62564L 10.9946,0.675901L 10.9946,3.9993 Z M 5.33775,17.6464C 2.70025,15.0089 2.30438,11.0068 4.11446,7.93788L 5.58524,9.40866C 4.51746,11.636 4.90642,14.3867 6.75197,16.2322C 7.27403,16.7543 7.86892,17.1599 8.50415,17.4491L 7.98078,19.4023C 7.01945,19.0123 6.11839,18.4271 5.33775,17.6464 Z "/>
</svg>
      <span class="ml-6">Seite zurücksetzen</span>
    </button>

    <!-- --- Togglet ein Banner für den Wartungsstatus -->
    <button v-on:click="toggle_maintenance_state" v-if="this.$store.getters.get_email === 'gerald@singel.plus' ||this.$store.getters.get_email === 'publishing-support@klett.de'" class="w-full flex items-center mt-8 px-3 py-2 space-x-4 border border-transparent shadow text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-red-200 focus:bg-white focus:outline-none ">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" width="24" height="24" viewBox="0 0 24.00 24.00" enable-background="new 0 0 24.00 24.00" xml:space="preserve">
        <path id="_xD83D__xDD39_-Icon-Color" class="st0" d="M16,0H6C2.7,0,0,2.7,0,6s2.7,6,6,6h10c3.3,0,6-2.7,6-6S19.3,0,16,0z M16,10H6
	c-2.2,0-4-1.8-4-4s1.8-4,4-4h10c2.2,0,4,1.8,4,4S18.2,10,16,10z M6,3C4.3,3,3,4.3,3,6s1.3,3,3,3s3-1.3,3-3S7.7,3,6,3z"/>
</svg>
      <span class="ml-6">Wartungsstatus umschalten</span>
    </button>

    <!-- Submit neu -->
    <!-- focus:ring-2 focus:ring-offset-2 focus:ring-green-500" -->
    <button
        v-if="this.$store.getters.get_form_status === 'OK'"
        v-on:click="upload_with_delay"
        class="w-full flex items-center mt-8 px-3 py-2 space-x-4 border border-transparent shadow text-sm leading-4 font-medium rounded-md text-green-900 bg-green-200 hover:bg-green-500 "
    >
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" width="24" height="24" viewBox="0 0 24.00 24.00" enable-background="new 0 0 24.00 24.00" xml:space="preserve">
	<path fill="#065F46" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 16.0012,3.99875L 8.99875,8.0425L 8.99875,15.9575L 16.0012,20L 22.9975,15.9575L 22.9975,8.0425M 16.0012,6.30875L 19.795,8.50125L 16.0012,10.6887L 12.2075,8.50125M 0,7.0025L 0,8.99875L 7.0025,8.99875L 7.0025,7.0025M 11.0013,10.1125L 15,12.4213L 15,17.1087L 11.0013,14.805M 21.0013,10.1125L 21.0013,14.805L 17.0025,17.1087L 17.0025,12.4213M 2.0025,11.0012L 2.0025,12.9975L 7.0025,12.9975L 7.0025,11.0012M 3.99875,15L 3.99875,17.0025L 7.0025,17.0025L 7.0025,15"/>
</svg>
      <span class="ml-6">{{ send_btn_text }}</span>
    </button>


    <!-- Download ZIP neu -->
    <a
        v-if="this.$store.getters.get_form_status === 'sent'"
        :href="zip_path"
        class="w-full flex items-center mt-8 px-3 py-2 space-x-4 border border-transparent shadow text-sm leading-4 font-medium rounded-md text-yellow-900 bg-yellow-200 hover:bg-yellow-500"
    >
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" width="24" height="24" viewBox="0 0 24.00 24.00" enable-background="new 0 0 24.00 24.00" xml:space="preserve">
        <path fill="#064E3B" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 4.9994,19.9981L 18.9994,19.9981L 18.9994,17.9981L 4.9994,17.9981M 18.9994,8.99807L 14.9994,8.99807L 14.9994,2.99807L 8.9994,2.99807L 8.9994,8.99807L 4.9994,8.99807L 11.9994,15.9981L 18.9994,8.99807 Z "/>
      </svg>
      <span class="ml-6">Download ZIP</span>
    </a>

    <!-- Workflow starten neu -->
    <a
        v-if="this.$store.getters.get_form_status === 'sent'"
        v-on:click="send_to_workflow"
        class="w-full flex items-center mt-8 px-3 py-2 space-x-4 border border-transparent shadow text-sm leading-4 font-medium rounded-md text-green-900 bg-green-200 hover:bg-green-500"
    >
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" width="24" height="24" viewBox="0 0 24.00 24.00" enable-background="new 0 0 24.00 24.00" xml:space="preserve">
        <path fill="#065F46" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 13,18L 14,18C 14.5523,18 15,18.4477 15,19L 22,19L 22,21L 15,21C 15,21.5523 14.5523,22 14,22L 10,22C 9.44772,22 9.00001,21.5523 9.00001,21L 2,21L 2,19L 9.00001,19C 9.00001,18.4477 9.44772,18 10,18L 11,18L 11,16L 4,16C 3.44771,16 3,15.5523 3,15L 3,11C 3,10.4477 3.44771,10 4,10L 20,10C 20.5523,10 21,10.4477 21,11L 21,15C 21,15.5523 20.5523,16 20,16L 13,16L 13,18 Z M 4,2L 20,2C 20.5523,2 21,2.44771 21,3L 21,6.99999C 21,7.55228 20.5523,7.99999 20,7.99999L 4,7.99999C 3.44772,7.99999 3,7.55228 3,6.99999L 3,3C 3,2.44771 3.44772,2 4,2 Z M 8.99999,6.00001L 9.99999,6.00001L 9.99999,4.00001L 8.99999,4.00001L 8.99999,6.00001 Z M 8.99999,14L 9.99999,14L 9.99999,12L 8.99999,12L 8.99999,14 Z M 5,4.00001L 5,6.00001L 7,6.00001L 7,4.00001L 5,4.00001 Z M 5,12L 5,14L 7,14L 7,12L 5,12 Z "/>
      </svg>
      <span class="ml-6">Workflow starten</span>
    </a>
  </div>
</template>
<script>
  const axios = require('axios');

  export default {
    name: 'Form',
    data () {
      return {
        defaultcss: [ "px-6", "h-screen", "overflow-auto", "text-left", "border-r"],
        kennzahl: "isbn",
        send_btn_text: "abschließen",
      }
    },
    props: {
      cssclasses: Array
    },
    components: {
    },
    computed: {
      maincss () {
        return [ ...this.defaultcss, ...this.cssclasses ];
      },
      form_sent: {
        get() { return this.$store.getters.get_form_sent },
        set(value) { this.$store.commit('set_form_sent', value) }
      },
      zip_path: {
        get() { return this.$store.getters.get_zip_path },
        set(value) {
          this.$store.commit('set_zip_path', value);
        }
      },
      email: {
        get() { return this.$store.getters.get_email },
        set(value) {
          this.$store.commit('set_email', value);
          this.$store.dispatch('validate');
        }
      },
      // mm_klappe_cover_links: {
      //   get() { return this.$store.getters.get_mm_klappe_cover_links },
      //   set(value) {
      //     this.$store.commit('set_mm_klappe_cover_links', value);
      //     this.$store.dispatch('validate');
      //   }
      // },
      // mm_klappe_cover_rechts: {
      //   get() { return this.$store.getters.get_mm_klappe_cover_rechts },
      //   set(value) {
      //     this.$store.commit('set_mm_klappe_cover_rechts', value);
      //     this.$store.dispatch('validate');
      //   }
      // },
      // mm_klappe_vorsatz_links: {
      //   get() { return this.$store.getters.get_mm_klappe_vorsatz_links },
      //   set(value) {
      //     this.$store.commit('set_mm_klappe_vorsatz_links', value);
      //     this.$store.dispatch('validate');
      //   }
      // },
      // mm_klappe_vorsatz_rechts: {
      //   get() { return this.$store.getters.get_mm_klappe_vorsatz_rechts },
      //   set(value) {
      //     this.$store.commit('set_mm_klappe_vorsatz_rechts', value);
      //     this.$store.dispatch('validate');
      //   }
      // },
      kennzahl_typ: {
        get() { return this.$store.getters.get_kennzahl_typ },
        set(value) {
          this.$store.commit('set_kennzahl_typ', value);
          this.$store.dispatch('validate');
        }
      },
      isbn: {
        get() { return this.$store.getters.get_isbn },
        set(value) {
          this.$store.commit('set_isbn', value);
          this.$store.dispatch('validate');
        }
      },
      wnumber: {
        get() { return this.$store.getters.get_wnumber },
        set(value) {
          this.$store.commit('set_wnumber', value);
          this.$store.dispatch('validate');
        }
      },
      ecnumber: {
        get() { return this.$store.getters.get_ecnumber },
        set(value) {
          this.$store.commit('set_ecnumber', value);
          this.$store.dispatch('validate');
        }
      },
      b_bib: {
        get() { return this.$store.getters.get_b_bib },
        set(value) {
          this.$store.commit('set_b_bib', value);
          this.$store.dispatch('validate');
        }
      },
      b_kanban: {
        get() { return this.$store.getters.get_b_kanban },
        set(value) {
          this.$store.commit('set_b_kanban', value);
          this.$store.dispatch('validate');
        }
      },
      b_dua: {
        get() { return this.$store.getters.get_b_dua },
        set(value) {
          this.$store.commit('set_b_dua', value);
          this.$store.dispatch('validate');
        }
      },
      b_kontroll: {
        get() { return this.$store.getters.get_b_kontroll },
        set(value) {
          this.$store.commit('set_b_kontroll', value);
          this.$store.dispatch('validate');
        }
      },
      b_kooperation: {
        get() { return this.$store.getters.get_b_kooperation },
        set(value) {
          this.$store.commit('set_b_kooperation', value);
          this.$store.dispatch('validate');
        }
      },
      b_webshop: {
        get() { return this.$store.getters.get_b_webshop },
        set(value) {
          this.$store.commit('set_b_webshop', value);
          this.$store.dispatch('validate');
        }
      },
      b_fremdrechte: {
        get() { return this.$store.getters.get_b_fremdrechte },
        set(value) {
          this.$store.commit('set_b_fremdrechte', value);
          this.$store.dispatch('validate');
        }
      },
      b_duamat: {
        get() { return this.$store.getters.get_b_duamat },
        set(value) {
          this.$store.commit('set_b_duamat', value);
          this.$store.dispatch('validate');
        }
      },
      b_pixeln: {
        get() { return this.$store.getters.get_b_pixeln },
        set(value) {
          this.$store.commit('set_b_pixeln', value);
          this.$store.dispatch('validate');
        }
      },
      n_vector_count: {
        get() { return this.$store.getters.get_n_vector_count },
        set(value) {
          this.$store.commit('set_n_vector_count', value);
          this.$store.dispatch('validate');
        }
      },
      ppi_resolution: {
        get() { return this.$store.getters.get_ppi_resolution },
        set(value) {
          this.$store.commit('set_ppi_resolution', value);
          this.$store.dispatch('validate');
        }
      },
      jpg_quality: {
        get() { return this.$store.getters.get_jpg_quality },
        set(value) {
          this.$store.commit('set_jpg_quality', value);
          this.$store.dispatch('validate');
        }
      },
    },
    watch: {
      b_pixeln (nu, old) {
        // console.log("summin happened: ", nu, old);
      }
    },
    methods: {
      /*
      let formData = new FormData();
      this.state.files.forEach(file => formData.append('files[]',file));
      let headers = { 'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2) };
      axios.post('/api/upload-images',formData,{headers: headers});
      */

      do_nothing () {
        // if ( this.state.dbg !== "strict") console.log("do nothing");
      },

      send_to_workflow () {
        console.log("holy smokes");
        console.log("call copy to workflow", "ktyp: " + this.kennzahl_typ);
        console.log("test" + "ktyp: " + this.kennzahl_typ);
        let kzahl = this.isbn;
        if ( this.kennzahl_typ === "wnumber") {
          kzahl = this.wnumber;
          console.log("Switching to wnumber: ", kzahl)
        } else if ( this.kennzahl_typ === "ecnumber") {
          kzahl = this.ecnumber;
          console.log("Switching to ecnumber: ", kzahl)
        } else {
          console.log("kennzahl ist " + this.kennzahl_typ)
        }

        axios({
          method: 'post',
          url: './api/copy_zip.php',
          data: {
            isbn: kzahl,
          }
        }).then(function(response) {
          console.log("File copied, now reload");
          alert("Datei wurde in den Hotfolder gelegt");
          location.reload();
        }).catch( function(e){
          console.error( e );
          alert("Etwas ist schief gegangen\n\nVersuchen Sie bitte, das ZIP zu downloaden und den Workflow manuell zu starten");
        });
        // axios.post(
        //     './api/copy_zip.php',
        //     {isbn: '1234'}
        // ).then(function(response) {
        //   console.log( response );
        // }).catch( function(e){
        //   console.error( e );
        // });
        //alert("\nDiese Funktion ist auf diesem Testserver noch nicht implementiert\rLaden sie das ZIP und füttern den Workflow mit der Hand.")
      },

      upload_with_delay () {
        this.form_sent = true;
        this.$store.dispatch('assemble_jt');
        this.send_btn_text = "... Moment ...";
        setTimeout( this.upload_all, 100);
      },
      upload_all () {
        var the_store = this.$store;
        if ( the_store.dbg !== "strict") console.log( "Paket schicken..." );
        the_store.commit('set_form_status', "sending");
        var data = {};
        var aux = document.forms["pondform"].getElementsByTagName("input");
        // var url = document.forms["pondform"].getAttribute("action");
        var url = "./api/submit.php";
        var jobname = document.querySelectorAll("input[name='jobname']")[0].value;
        var jobticket = document.querySelectorAll("input[name='jobticket']")[0].value;
        var fp_inputs = document.querySelectorAll("input[name='filepond[]']");
        var fp_files = [];
        for ( var n = 0; n < fp_inputs.length; n++ ) { fp_files.push( fp_inputs[n].value )};
        // if ( the_store.dbg !== "strict") console.log( jobname, jobticket.substr(0,32), fp_files);

        var formdata = new FormData();
        formdata.append('jobname', jobname);
        formdata.append('jobticket', jobticket);
        for ( var n = 0; n < fp_files.length; n++ ) {
          formdata.append('filepond[]', fp_files[n]);
        }

        data = {
          jobname: jobname,
          jobticket: jobticket,
          filepond: fp_files
        };
        if ( the_store.dbg !== "strict") console.log("url", url);
        if ( the_store.dbg !== "strict") console.log("jobname", jobname);
        if ( the_store.dbg !== "strict") console.log("jobticket", jobticket);
        if ( the_store.dbg !== "strict") console.log("filepond", fp_files);

        axios.post(
            url,
            formdata,
            {headers: {'Content-Type': 'multipart/form-data' } }
        ).then( function( response ){
          if ( the_store.dbg !== "strict") console.log( "response", response );
          the_store.commit('set_form_status', "sent");
          the_store.commit('set_zip_path', 'api/' + response.data);
        }).catch( function(error) {
          if ( the_store.dbg !== "strict") console.error( error );
        })
      },
      reload_page () {
        if ( confirm("Wirklich zurücksetzen?\nAlle Eingaben gehen verloren") ) {
          location.reload();
        }
      },
      toggle_maintenance_state () {
        axios.get(
            './api/toggle_maintenance.php'
        ).then( ( response ) => {
          if ( this.$store.dbg !== "strict") console.log( "response on toggle", response.data, typeof response.data );
          let _state = response.data === true;
          this.$store.commit('set_maintenance_state', _state)
        }).catch( function(error) {
          if ( this.$store.dbg !== "strict") console.error( error );
        })
      }
    }
  }
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: all .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#1D1D1D;}
</style>
