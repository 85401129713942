<template>
  <div v-if="name!=='crnt' || list.length" >
    <label class="text-gray-600 mt-6 block text-sm ">
      {{ label }}
    </label>
<!--v-bind="dragOptions"
<transition-group type="transition" :name="!drag ? 'flip-list' : null">
</transition-group>
handle=".handle"
<svg class="handle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" width="24" height="24" viewBox="0 0 24.00 24.00" enable-background="new 0 0 24.00 24.00" xml:space="preserve">
	<path fill="#888888" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 7,19L 7,17L 9,17L 9,19L 7,19 Z M 11,19L 11,17L 13,17L 13,19L 11,19 Z M 15,19L 15,17L 17,17L 17,19L 15,19 Z M 7,15L 7,13L 9,13L 9,15L 7,15 Z M 11,15L 11,13L 13,13L 13,15L 11,15 Z M 15,15L 15,13L 17,13L 17,15L 15,15 Z M 7,11L 7,9.00001L 9,9.00001L 9,11L 7,11 Z M 11,11L 11,9.00001L 13,9.00001L 13,11L 11,11 Z M 15,11L 15,9.00001L 17,9.00001L 17,11L 15,11 Z M 7,7.00001L 7,5.00001L 9,5.00001L 9,7.00001L 7,7.00001 Z M 11,7.00001L 11,5.00001L 13,5.00001L 13,7.00001L 11,7.00001 Z M 15,7.00001L 15,5.00001L 17,5.00001L 17,7.00001L 15,7.00001 Z "/>
</svg>
-->
    <draggable class="list-group bg-white border border-gray-300 rounded border-dashed p-3 "
               v-model="list"
               group="meine_listen"

               @start="drag=true"
               @end="drag=false"

    >

        <div class="list-group-item border-gray-300 py-1 flex justify-between space-x-2 handle" v-for="item in list" :key="list.indexOf(item)">
          <!-- <img class="handle ml-0" src="@/assets/handle.png" /> -->
          <span class="text-sm md:text-xs lg:text-sm"> <span class="text-gray-400">&#9776; </span> {{item.name}}</span>
          <span class="text-xs cursor-pointer" @click="rm_entry(item)">&#128465;</span>
          &nbsp;
        </div>

    </draggable>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
export default {
  name: 'DraggableList',
  components: {
    draggable,
  },
  props: {
    name: String,
    label: String,
  },
  computed: {
    list: {
      get() {
        return this.$store.getters["get_" + this.name + "_files"]
      },
      set(value) {
        // console.log("commit: ", this.name);
        this.$store.commit("set_files", {files: value, list_name: this.name});
        this.$store.dispatch('validate');
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "meine_listen",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    rm_entry( item ) {
      // https://stackoverflow.com/questions/48818110/access-refs-from-other-components-not-in-the-same-level-as-current-component
      this.$root.$emit('rm_file_from_pond', item.name);
    }
  }
}
</script>
<style>
  .handle {
    cursor: grab;
  }
  .handle:active {
    cursor: grabbing;
  }
  .ghost {
    opacity: 0.3;
    background: #c8ebfb;
  }
</style>