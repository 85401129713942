<template>
  <div :class="maincss" class="relative" id="app1">
    <h2 class="mb-4 font-bold">Wählen Sie Ihre PDFs aus</h2>
    <form action="api/submit.php" method="post" enctype="multipart/form-data" name="pondform">
<!--      <button type="submit" class="border border-green-400 rounded px-3 px-1 my-4">Submit</button><br>-->
<!--      <button type="button" v-on:click="log_files" class="border rounded px-3 px-1 my-4">log files</button><br>-->
      <input name="jobname" type="hidden" v-model="jobname" readonly />
      <input name="jobticket" type="hidden"  v-model="jobticket" readonly />
      <file-pond
          name="filepond[]"
          ref="pond"
          class-name="my-pond"
          allow-multiple="true"
          accepted-file-types="application/pdf"
          dropOnPage="false"
          instantUpload="true"
          chunkUploads="true"
          chunkSize="2000000"
          label-idle="Ziehen Sie die PDFs in diesen Bereich oder <br><span class='underline'>klicken Sie hier</span> für den Dateiauswahldialog"
          labelButtonRemoveItem="Löschen"
          labelFileProcessing="wird gesendet"
          labelFileProcessingAborted="Senden abgebrochen"
          labelFileProcessingComplete="fertig gesendet"
          labelFileProcessingError="Fehler beim Senden"
          labelFileTypeNotAllowed="Dateityp wird nicht unterstützt"
          labelInvalidField="Nur PDF erlaubt"
          labelTapToCancel="Abbrechen"
          labelTapToRetry="neuer Versuch"
          labelTapToUndo="Rückgängig machen"
          server="./api/"
          credits="https://wiki.klett.de/x/jAWnBQ, ..."
          v-on:addfile="handle_new_file"
          v-on:processfile="handle_uploaded_file"
          v-on:processfileabort="handle_aborted_file"
          v-on:removefile="handle_remove_file"
      />
    </form>

<!--    <button v-on:click="reload_page" class="w-full flex items-center mt-8 px-3 py-2 space-x-4 border border-transparent shadow text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-red-200 focus:bg-white focus:outline-none ">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" width="24" height="24" viewBox="0 0 24.00 24.00" enable-background="new 0 0 24.00 24.00" xml:space="preserve">-->
<!--	<path fill="#000000" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 10.9946,3.9993C 13.0452,3.9993 15.0888,4.77005 16.6515,6.33275C 19.7769,9.45816 19.7697,14.514 16.6444,17.6394C 14.8067,19.4771 12.3015,20.2367 9.91388,19.9153L 10.4373,17.9619C 12.1535,18.1207 13.9253,17.5442 15.2372,16.2322C 17.5778,13.8917 17.5778,10.0875 15.2372,7.74697C 14.0634,6.57317 12.529,6.01455 10.9946,6.02163L 10.9946,10.5754L 6.04487,5.62564L 10.9946,0.675901L 10.9946,3.9993 Z M 5.33775,17.6464C 2.70025,15.0089 2.30438,11.0068 4.11446,7.93788L 5.58524,9.40866C 4.51746,11.636 4.90642,14.3867 6.75197,16.2322C 7.27403,16.7543 7.86892,17.1599 8.50415,17.4491L 7.98078,19.4023C 7.01945,19.0123 6.11839,18.4271 5.33775,17.6464 Z "/>-->
<!--</svg>-->
<!--      <span class="ml-6">Seite zurücksetzen</span>-->
<!--    </button>-->

<!--    <button v-on:click="reload_page" class="mt-16 px-3 py-1 shadow w-full bg-gray-100 border border-gray-300 rounded">Seite zurücksetzen</button>-->
<!--    <button v-on:click="show_fp_ids" class="mt-16 px-3 py-1 shadow w-full bg-blue-200 border border-blue-800 rounded">IDs anzeigen (debug)</button>-->

    <div id="dbg_area"></div>

    <div v-if="form_sent" v-on:click="do_nothing" class="absolute top-0 left-0 bottom-0 right-0 z-50"  style="background: rgba(255,255,255,0.2); backdrop-filter: blur(2px);"></div>

  </div>
</template>
<script>
  import vueFilePond from 'vue-filepond';
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

  import 'filepond/dist/filepond.min.css';
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

  // Create FilePond component
  const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview );

  export default {
    name: 'Dropzone',
    components: {
      vueFilePond
    },
    props: {
      cssclasses: Array
    },
    data () {
      return {
        filelist: [], // Store our uploaded files
        defaultcss: [ "px-6", "text-left", "border-r"],
      }
    },
    mounted() {
      // https://stackoverflow.com/questions/48818110/access-refs-from-other-components-not-in-the-same-level-as-current-component
      this.$root.$on('rm_file_from_pond', ( filename ) => {
        var allfiles = this.$refs.pond.getFiles();
        for ( var n = allfiles.length-1; n >= 0 ; n-- ) {
          if ( allfiles[n].filename === filename ) {
            console.log( "filestatus", allfiles[n].status)
            if ( allfiles[n].status == 3 || allfiles[n].status == 9 ) this.$store.commit('decr_waiting_for_uploaded');
            this.$refs.pond.removeFile( allfiles[n].id );
          }
        }
      });
    },
    computed: {
      my_crnt_files () {
        return this.$store.getters.get_crnt_files;
      },
      form_sent () {
        return this.$store.getters.get_form_sent;
      },
      maincss () {
        return [ ...this.defaultcss, ...this.cssclasses ];
      },
      isbn: {
        get() { return this.$store.getters.get_isbn },
        set(value) { this.$store.commit('set_isbn', value) }
      },
      jobname: {
        get() { return this.$store.getters.get_jobname },
        set(value) { this.$store.commit('set_jobname', value) }
      },
      jobticket: {
        get() { return this.$store.getters.get_jobticket },
        set(value) { this.$store.commit('set_jobticket', value) }
      },
    },
    methods: {
      do_nothing () {
        console.log("do nuttin’");
      },
      check_existing_files( fname ) {
        let gips_schon = false;
        let lists = [ 'cover_files', 'kvorsatz_files', 'vorsatz_files', 'buchblock_files', 'nachsatz_files'];
        for ( let m = 0; m <  lists.length; m++  ) {
          let listname = lists[m];
          if (this.$store.state.dbg) console.log( listname );
          // state[listname][insert_here].name
          for ( let n = 0; n <  this.$store.state[listname].length; n++ ) {
            if (this.$store.state.dbg) console.log( this.$store.state[listname][n].name + " ?= " + fname);
            if ( this.$store.state[listname][n].name === fname ) {
              gips_schon = true;
            }
          }
        }
        if (this.$store.state.dbg) console.log( "gips schon: " + gips_schon );
        return gips_schon
      },
      handle_new_file: function( err, fp_file ) {
        if (this.$store.state.dbg) console.log('File has inited');
        if (this.$store.state.dbg) console.log( fp_file.filename, fp_file );
        if ( this.check_existing_files(fp_file.filename )) {
          this.$store.commit('add_crnt_files', [fp_file.file]);
          if (this.$store.state.dbg) console.log("add file commit done");
          fp_file.abortLoad();
        } else {
          this.$store.commit('add_crnt_files', [fp_file.file]);
          if (this.$store.state.dbg) console.log("add file commit done");
          this.$store.commit('incr_waiting_for_uploaded');
          if (this.$store.state.dbg) console.log("waiting increased");
          this.$store.dispatch('validate');
          if (this.$store.state.dbg) console.log("validated");
        }
      },
      handle_aborted_file( file ) {
        this.$store.commit('decr_waiting_for_uploaded');
        this.$store.dispatch('validate');
      },
      handle_uploaded_file( err, file ) {
        // console.log("uploaded", file);
        if ( ! err ) {
          this.$store.commit('decr_waiting_for_uploaded');
          this.$store.dispatch('validate');
        }
      },
      handle_remove_file (err, file) {
        if ( ! err ) {
          if (this.$store.state.dbg) console.log( "remove", file);
          this.$store.commit('remove_file_from_lists', file.filename);
          this.$store.dispatch('validate');
        } else {
          console.error( err );
        }
      },
      log_files () {
        // console.log( this.$refs.pond.getFiles() );
      },
      reload_page () {
        if ( confirm("Wirklich zurücksetzen?\nAlle Eingaben gehen verloren") ) {
          location.reload();
        }
      },
      show_fp_ids () {
        var dbg_area = document.getElementById("dbg_area");
        var allfiles = this.$refs.pond.getFiles();
        for ( var n = allfiles.length-1; n >= 0 ; n-- ) {
          dbg_area.innerHTML += "<li>" + allfiles[n].id + " (" + allfiles[n].serverId + "): " + allfiles[n].filename + "</li>";
        }
      }
    }
  }
</script>
