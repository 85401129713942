<template>
  <div id="app" class="bg-white">
    <div id="nav" class="bg-gray-300 p-3 flex justify-between items-center">
      <div class="flex items-center mx-4 my-1 space-x-4">
        <img src="@/assets/klett-logo.svg" class="h-8 "/>
        <span class="text-lg font-bold">PDF-App</span>
        <span class="text-xs text-gray-700" style="margin-left: 6px; margin-top: 4px;">v1.2.0</span>
      </div>
      <div class="text-sm text-red-700 mr-4"><a href="https://wiki.klett.de/x/jAWnBQ" target="_blank">Dokumentation</a></div>
    </div>
    <transition name="fade">
      <div v-if="this.$store.getters.get_validation_nok" class="bg-yellow-200 py-1 px-6" v-html="this.$store.getters.get_validation_msg"></div>
    </transition>
    <transition name="fade">
      <div v-if="this.$store.getters.get_maintenance_state === true" class="bg-red-600 text-white py-1 px-6" v-html="maintenance_msg"></div>
    </transition>
    <div class="home box-border mt-8 xl:flex xl:justify-start">
      <dropzone :cssclasses="['w-full xl:w-4/12 mx-2 pb-6 pt-3 bg-gray-100' ]"></dropzone>
      <sorter :cssclasses="['w-full xl:w-5/12 xl:my-0 my-8 mx-2 pb-6 pt-3 bg-gray-100 ']"></sorter>
      <eingabe :cssclasses="['w-full xl:w-3/12 xl:my-0 my-8 mx-2 pb-6 pt-3 bg-gray-100']">test</eingabe>
    </div>
  </div>
</template>

<script>
  const axios = require('axios');

  // @ is an alias to /src
  import Dropzone from '@/components/Dropzone.vue'
  import Sorter from '@/components/Sorter.vue'
  import Eingabe from '@/components/Eingabe.vue'

export default {
  name: 'App',
  data () {
    return {
      maintenance_msg: "<p>Das PDF-App Backend befindet sich derzeit in Wartung. Aufträge werden danach automatisch abgearbeitet.</p>"
    }
  },
  components: {
    Dropzone,
    Sorter,
    Eingabe
  },
  computed: {
    isbn: {
      get() { return this.$store.getters.get_isbn },
      set(value) { this.$store.commit('set_isbn', value) }
    },
    form_sent: {
      get() { return this.$store.getters.get_form_sent },
      set(value) { this.$store.commit('set_form_sent', value) }
    }

  },
  watch: {
    // show_it (nu, old) {
    //   // console.log("summin happened: ", nu, old);
    // }
  },
  mounted() {
    let mail = localStorage.getItem('kps4_email');
    if ( mail ) this.$store.commit('set_email', mail);
    this.$store.dispatch('validate');

    // 3.7.23: Wir führen einen Maintenance-State ein
    axios.get(
        './api/get_maintenance.php'
    ).then( ( response ) => {
      if ( this.$store.dbg !== "strict") console.log( "response on mounted", response.data, typeof response.data );
      if ( response.data === true ) {
        this.$store.commit('set_maintenance_state', true);
      } else {
        this.$store.commit('set_maintenance_state', false);
      }
    }).catch( function(error) {
      console.error( error );
    })

  }
}
</script>

<style>

</style>
